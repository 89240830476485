import React from "react"
import HybridCloudForm from "../components/forms/hybridcloud"

import Layout from "../components/layout"
import SEO from "../components/seo"

const HybridCloudPage = () => (
  <Layout>
    <SEO title="Our Focuses" description="" lang="en" meta={[]} />
    <section className="technology-section form-page">
      <h1 className="mt-5 text-center">
        Flapmax <span>Hybrid Cloud</span>
      </h1>

      <h2 className="mb-5 pb-3 text-center">
        Please send us any inquiries regarding Hybrid Cloud.
      </h2>
      <HybridCloudForm />
    </section>
  </Layout>
)

export default HybridCloudPage
